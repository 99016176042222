import React, { useState } from "react";
import {
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { PayButton } from "../../Styles/UIKit/Button";
import { Row } from "../../Styles/Layout/Row";

import CardSection from "./CardSection";

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [paid, setPaid] = useState(false);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setLoading(false);
    }
    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.origin,
      },
      // Uncomment below if you only want redirect for redirect-based payments
      redirect: 'if_required',
    });

    if (error) {
      // Show error to your customer (e.g., insufficient funds)
      setLoading(false);
      window.alert(error.message);
    } else {
      setPaid(true);
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {!paid ? (
          <>
            <CardSection />
            <Row
              margin="1rem auto"
              maxWidth="50rem"
              justifyContent="space-between"
            >
              <PayButton disabled={loading}>
                {!loading ? "Pay With Credit / Debit Card" : "Processing..."}
              </PayButton>
            </Row>
          </>
        ) : (
          <h1 className="main-header">Paid successfully</h1>
        )}
      </form>
    </>
  );
}
