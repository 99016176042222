import { Label } from "../Styles/UIKit/Text";
import { DescriptionInputText } from "../Styles/UIKit/DescriptionInput";
import Box from "@mui/material/Box";

interface ITextControlProps {
  value: string;
  handleValueChange: (value: string) => void;
  placeholder: string;
  required?: boolean;
  label: string;
  style?: any;
  sx?: any;
}

const TextAreaControl = ({
  value,
  handleValueChange,
  label,
  placeholder,
  required,
  style,
  sx,
}: ITextControlProps) => {
  return (
    <Box px={4} py={1} sx={sx}>
      <Label color="#1b34ce">{label}</Label>
      <DescriptionInputText
        placeholder={placeholder}
        style={{ marginTop: "0.5rem", ...style }}
        required={required}
        value={value}
        rows={5}
        onChange={(e) => handleValueChange(e.target.value)}
      />
    </Box>
  );
};

export default TextAreaControl;
