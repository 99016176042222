import styled from "styled-components";

interface Image {
    width?: string;
    height?: string;
    margin?: string;
}

export const Image = styled.img`
    width: ${({width}: Image) => width || "auto"};
    height: ${({height}: Image) => height || "auto"};
    margin: ${({margin}: Image) => margin || "0"}
    display: block;
`;

export const CenteredImage = styled.img`
    width: ${({width}: Image) => width || "auto"};
    height: ${({height}: Image) => height || "auto"};
    margin: ${({margin}: Image) => margin || "0"}
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;