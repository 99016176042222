import { Label } from "../Styles/UIKit/Text";
import InputMask from "react-input-mask";
import Box from "@mui/material/Box";
import "./style.css";

interface ITextControlProps {
  value: string;
  handleValueChange: (value: string) => void;
  required?: boolean;
  label?: string;
}

const MaskInputControl = ({
  value,
  handleValueChange,
  label,
  required,
}: ITextControlProps) => {
  return (
    <Box px={4} py={1}>
      <Label color="#1b34ce">{label}</Label>
      <InputMask
        required
        style={{ 
          marginTop: "0.5rem",
          border: "1px solid #eef4ff",
          background: "#f6f9ff",
        }}
        autoComplete={"tel-national"}
        type={"tel"}
        inputMode={"tel"}
        className="input-mask "
        mask="999 999 9999"
        value={value}
        onChange={(e) => handleValueChange(e.target.value)}
      />
    </Box>
  );
};

export default MaskInputControl;
