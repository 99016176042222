import styled from "styled-components";

export interface ITextProps {
    size?: string;
    color?: string;
    margin?: string;
    weight?: string;
    padding?: string;
    fontStyle?: string;
    textAlign?:  "center" | "end" | "start";
    justifyContent?: "center" | "flex-end" | "flex-start" | "space-between" | "space-around";
    fontFamily?: string;
}

export const Text = styled.p`
    display: flex;
    text-align: ${(props: ITextProps) => props.textAlign ? props.textAlign : "center"};
    font-size: ${(props: ITextProps) => props.size || "0.93rem"};
    font-weight: ${(props: ITextProps) => props.weight || "300"};
    font-style: ${(props: ITextProps) => props.fontStyle || "normal"};
    color: ${(props: ITextProps) => props.color || "rgb(20, 20, 20)"};
    margin: ${(props) => props.margin || "1px"};
    padding: ${(props) => props.padding || "0"};
`;

export const Label = styled.label`
    font-size: ${(props: ITextProps) => props.size || "0.93rem"};
    font-weight: ${(props: ITextProps) => props.weight || "bold"};
    font-family: ${(props: ITextProps) => props.fontFamily || "Archivo"};
    color: ${(props: ITextProps) => props.color || "rgb(20, 20, 20)"};
    margin: ${(props) => props.margin || "0"};
    padding: ${(props) => props.padding || "0"};
`;

export const Header1 = styled.h1`
    font-size: ${(props: ITextProps) => props.size || "1.3rem"};
    font-weight: ${(props: ITextProps) => props.weight || "bold"};
    color: ${(props: ITextProps) => props.color || "rgb(20, 20, 20)"};
    margin: ${(props) => props.margin || "10px"};
    padding: ${(props) => props.padding || "0"};
`;

export const Header2 = styled.h2`
    font-size: ${(props: ITextProps) => props.size || "1rem"};
    font-weight: ${(props: ITextProps) => props.weight || "bold"};
    color: ${(props: ITextProps) => props.color || "rgb(20, 20, 20)"};
    margin: ${(props) => props.margin || "7px"};
    padding: ${(props) => props.padding || "0"};
`;

export const Header3 = styled.h3`
    font-size: ${(props: ITextProps) => props.size || "0.93rem"};
    font-weight: ${(props: ITextProps) => props.weight || "bold"};
    font-family: ${(props: ITextProps) => props.fontFamily || "Archivo"};
    color: ${(props: ITextProps) => props.color || "rgb(20, 20, 20)"};
    margin: ${(props) => props.margin || "5px"};
    padding: ${(props) => props.padding || "0"};
`;