import { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { CalendarPicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "./VideoAPIPreview";
import moment from "moment";
import { Dayjs } from "dayjs";
import "./style.css";

type Props = {
  shopName: string;
  shopAddress: string;
  onNext: (step: number, videoUrl: string, scheduledAt: string, scheduledAtAsLocal: string) => void;
};

const timeList = [
  { key: 1, value: "9:00 AM", color: " #DFDFDF" },
  { key: 2, value: "10:00 AM", color: " #C4C4C4" },
  { key: 3, value: "11:00 AM", color: " #838487" },
  { key: 4, value: "12:00 PM", color: " #DFDFDF" },
  { key: 5, value: "1:00 PM", color: " #C4C4C4" },
  { key: 6, value: "2:00 PM", color: " #838487" },
  { key: 7, value: "3:00 PM", color: " #DFDFDF" },
  { key: 8, value: "4:00 PM", color: " #C4C4C4" },
  { key: 9, value: "5:00 PM", color: " #838487" },
];

export default function ScheduledServiceSelector({
  shopName,
  shopAddress,
  onNext,
}: Props) {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [selectedTime, setSelectedTime] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorMessage(undefined);
  };

  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
  };

  const handleContinue = () => {
    if (!selectedDate || !selectedTime) {
      setErrorMessage("Please select date and time");
      return;
    }
    const scheduledAt = `${selectedDate.format("YYYY/MM/DD")} ${selectedTime}`;
    const isoDateTime = moment(scheduledAt).toISOString();
    const scheduledAtAsLocal = moment(scheduledAt).format("L LT");
    console.log("Continue button clicked", isoDateTime, scheduledAtAsLocal);
    onNext(3, "", isoDateTime, scheduledAtAsLocal);
  };

  return (
    <>
      <Box py={3} sx={{ bgcolor: "#1B34CE" }}>
        <Typography
          variant="h6"
          mt={5}
          sx={{
            color: "#FFFFFF",
            fontWeight: "bold",
            textAlign: "center",
            fontSize: "1.3rem",
          }}
        >
          {shopName}
        </Typography>
        <Box mt={1} sx={{ px: "2rem" }} textAlign="center">
          <Typography
            variant="subtitle2"
            sx={{ lineHeight: 1.5, color: "white" }}
          >
            {shopAddress}
          </Typography>
        </Box>
        <Box sx={{ px: "2rem", mt: "2.5rem", mb: "1rem" }}>
          <Typography
            variant="body1"
            sx={{ fontSize: "1rem", color: "#FFFFFF", fontWeight: "bold" }}
          >
            Schedule your upcoming vehicle service at your convenience below:
          </Typography>
        </Box>
        <Box sx={{ bgcolor: "#1B34CE", height: "5rem" }} />
      </Box>
      <Box sx={{ mt: "-5rem", p: 2 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CalendarPicker date={selectedDate} onChange={handleDateChange} />
        </LocalizationProvider>
      </Box>
      <Box
        m={2}
        p={2}
        sx={{ border: "1.5px solid #1B34CE", borderRadius: "0.5rem" }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          Select Time:
        </Typography>
        <Box
          mt={2}
          rowGap={3}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(3, 1fr)",
          }}
        >
          {timeList.map((item) => {
            return (
              <Button
                key={item.key}
                variant="contained"
                onClick={() => setSelectedTime(item.value)}
                sx={{
                  backgroundColor: item.color,
                  color: "#343434",
                  fontWeight: "bold",
                  borderRadius: "0.5rem",
                  "&:hover": {
                    color: "#fff",
                    backgroundColor: "#1B34CE",
                  },
                }}
              >
                {item.value}
              </Button>
            );
          })}
        </Box>
      </Box>
      <Button
        variant="contained"
        onClick={handleContinue}
        sx={{
          textTransform: "none",
          bgcolor: "#1B34CE",
          my: 3,
          mr: "1rem",
          float: "right",
        }}
      >
        Continue! 🏁
      </Button>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
