import axios, { AxiosInstance } from "axios";

export class JobsAPI {
  private http: AxiosInstance;
  private paymentHttp: AxiosInstance;

  constructor(authorizationToken?: string) {
    this.http = axios.create({
      // baseURL: "https://api.motorvate.app/jobs",
      baseURL: "https://apistage.motorvate.app/jobs",
      headers: {
        Authorization: authorizationToken,
      },
    });
    this.paymentHttp = axios.create({
      // baseURL: "https://api.motorvate.app/stripe",
      baseURL: "https://apistage.motorvate.app/stripe",
      headers: {
        Authorization: authorizationToken,
      },
    });
  }

  public validateToken = async (key: string) => {
    const response = await this.http.get(`/token/${key}`);
    if (response && response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  };

  public getInquiryDetails = async (id: string) => {
    const response = await this.http.get(`/inquiry/${id}`);
    if (response && response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  };

  public getScheduledInfo = async (id: string) => {
    const response = await this.http.get(`/scheduled_service/${id}`);
    if (response && response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  };

  public confirmInvoice = async (key: string) => {
    return await this.http.post(`/confirminvoicedetails/?invoice_key=${key}`);
  };

  public confirmDeposit = async (key: string, payload: any) => {
    return await this.http.post(`/confirm_deposit/?inquiryID=${key}`, payload);
  };

  public createNewInquiry = async (payload: any, query: string) => {
    return await this.http.post(`/customerinquiry/?key=${query}`, payload);
  };

  public createNewScheduleInquiry = async (payload: any, query: string) => {
    return await this.http.post(
      `/customer_schedule_inquiry/?key=${query}`,
      payload
    );
  };

  public confirmScheduledService = async (key: string) => {
    return await this.http.post(
      `/scheduled_service/confirm_service/?id=${key}`
    );
  };

  public getPaymentIntent = async (key: string) => {
    const response = await this.paymentHttp.get(
      `/create_payment_intent/${key}`
    );
    if (response && response.status === 201) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  };

  public getEstimationPaymentIntent = async (key: string) => {
    const response = await this.paymentHttp.get(
      `/estimation_payment_intent/${key}`
    );
    if (response && response.status === 201) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  };

  public getAffirmPaymentIntent = async (key: string) => {
    const response = await this.paymentHttp.get(
      `/create_affirm_payment_intent/${key}`
    );
    if (response && response.status === 201) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  };
}

export default new JobsAPI();
