import Amplify, {Auth} from "aws-amplify";
import {withAuthenticator} from "aws-amplify-react";
import * as React from "react";
import './App.css';
import config from "./aws-config.js";

Amplify.configure(config);

const App: React.FC = () => {
  const [user, setUser] = React.useState("");
  const [token, setToken] = React.useState("");
  Auth.currentAuthenticatedUser({
    bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
  }).then((user) => {
    setUser(user.attributes.email);
    setToken(user.signInUserSession.accessToken.jwtToken);
    console.log("user", token);
  })
  .catch(err => console.log(err));

  return (
    <div className="App">
      <header className="App-header">
        <h1>Signed as: {user}</h1>
        <button className="App-link" onClick={() => Auth.signOut()}>Logout</button>
      </header>
    </div>
  );
}

export default withAuthenticator(App);
