import React, { useEffect, useState } from "react";
import CustomerEstimateForm from "../Components/CustomerEstimateForm";
import jobsAPI from "../ClientAPI/jobsAPI";
import { Container } from "../Styles/Layout/Container";
import { capitalizeFirstLetter } from "../service/helper";
import StripeCheckout from "../Components/Stripe/index";
import CustomerServiceFormStepOne from "../Components/CustomerServiceFormStepOne";
import CustomerServiceFormSuccess from "../Components/CustomerServiceFromSuccess";

interface InquiryToken {
  queryVal: string;
}

const CustomerEstimatePage = ({ queryVal }: InquiryToken) => {
  const [loading, setLoading] = useState(true);
  const [shopName, setShopName] = useState("");
  const [shopAddress, setShopAddress] = useState("");
  const [shopPhone, setShopPhone] = useState("");
  const [inquiryData, setInquiryData] = useState<any>({});
  const [isValid, setIsValid] = useState(false);
  const [hasPaymentIntent, setHasPaymentIntent] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState({});
  const [isPreview, setIsPreview] = useState(false);
  const [isServiceQuote, setIsServiceQuote] = useState(false);
  const [queryInquiryId, setQueryInquiryId] = useState("");
  const [isValidBank, setIsValidBank] = useState(false);
  const [noPayment, setNoPayment] = useState(false);
  const [viewStep, setViewStep] = useState<number>(1);

  let contactInfo: object;

  useEffect(() => {
    const splittedUrl = queryVal.split("pre_");
    let inquiryKey = queryVal;
    if (splittedUrl.length === 2) {
      inquiryKey = splittedUrl[1];
      setIsPreview(true);
    }
    const otherSplittedUrl = inquiryKey.split("__sqadd");
    if (otherSplittedUrl.length === 2) {
      inquiryKey = otherSplittedUrl[0];
      setIsServiceQuote(true);
    }
    setQueryInquiryId(inquiryKey);
    jobsAPI
      .getInquiryDetails(inquiryKey)
      .then((result) => {
        if (
          !result.inquiry.isDeposited &&
          result.inquiry.inquiryDetails.length >= 0
        ) {
          setIsValid(true);
          setShopName(result.shopData.businessName || "");
          setShopAddress(result.shopData.businessAddress || "");
          setShopPhone(result.shopData.shopTelephone);
          setIsValidBank(result.shopData.isValidBank);
          getInquiryInfo(result.inquiry);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const getInquiryInfo = (data: any) => {
    contactInfo = {
      name:
        capitalizeFirstLetter(data.customerFirstName) +
        " " +
        capitalizeFirstLetter(data.customerLastName),
      phone: data.customerPhone,
      email: data.customerEmail,
    };
    const inquiryInfo = {
      contactInfo: contactInfo,
      inquiry: data,
      inquiryDetail: data.inquiryDetails,
    };
    setInquiryData(inquiryInfo);
  };

  const submitEstimate = async (notes: string) => {
    try {
      const response = await jobsAPI.confirmDeposit(queryInquiryId, {
        note: notes,
      });
      console.log("=== Response ===", response);
      if (response.status === 200) {
        if (isValidBank) {
          const paymentIntentResult = await jobsAPI.getEstimationPaymentIntent(
            queryInquiryId
          );
          if (paymentIntentResult) {
            setPaymentIntent(paymentIntentResult);
            setHasPaymentIntent(true);
          }
        } else {
          setViewStep(3);
        }
      }
    } catch (err: any) {
      setHasPaymentIntent(false);
      window.alert(err.message);
    }
  };

  const changeViewStep = async (step: number) => {
    setViewStep(step);
  };

  return (
    <>
      {!hasPaymentIntent ? (
        loading ? (
          <Container>
            <h1 className="main-header">Loading...</h1>
          </Container>
        ) : isValid ? (
          !noPayment ? (
            <>
              {viewStep === 1 && (
                <CustomerServiceFormStepOne
                  shopName={shopName || "Urban Vinyl"}
                  isInquiry={false}
                  onNext={changeViewStep}
                />
              )}
              {viewStep === 2 && (
                <Container>
                  <CustomerEstimateForm
                    shopName={shopName || ""}
                    shopAddress={shopAddress || ""}
                    shopPhone={shopPhone}
                    inquiryData={inquiryData}
                    isPreview={isPreview}
                    isQuote={isServiceQuote}
                    onSubmit={submitEstimate}
                  />
                </Container>
              )}
              {viewStep === 3 && (
                <CustomerServiceFormSuccess
                  shopName={shopName || "Urban Vinyl"}
                  shopAddress={shopAddress}
                  customerName={inquiryData.contactInfo.name || ""}
                  title1="You have successfully confirmed your vehicle service with us at "
                  title2=""
                />
              )}
            </>
          ) : (
            <Container>
              <p className="font-big-center">
                <b>Successfully Confirmed</b> <br />
                <br />
                We'll reply to further assist with your vehicle service shortly
                🚗💨
              </p>
            </Container>
          )
        ) : (
          <Container>
            <h1 className="main-header">
              Oops…page expired, contact to shop owner
            </h1>
          </Container>
        )
      ) : (
        <Container>
          <StripeCheckout intent={paymentIntent} />
        </Container>
      )}
    </>
  );
};

export default CustomerEstimatePage;
