import styled from "styled-components";

interface IColProps {
    grow?: number;
    shrink?: number;
    order?: number;
    basis?: string;
    justifyContent?: "center" | "flex-end" | "flex-start" | "space-between" | "space-around";
}

export const Col = styled.div`
    display: flex;
    justify-content: ${(props: IColProps) => props.justifyContent ? props.justifyContent : "flex-start"};
    flex-grow: ${(props: IColProps) => props.grow || 1};
    flex-shrink: ${(props: IColProps) => props.shrink || 0};
    flex-basis: ${(props: IColProps) => props.basis || "0"};
    order: ${(props: IColProps) => props.order || 0};
`;