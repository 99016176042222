import { CenteredImage } from "../Styles/UIKit/Image";
import CarBackground from "../assets/service_car.png";
import { Row } from "../Styles/Layout/Row";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useCallback } from "react";

type Props = {
  shopName: string;
  onNext: (nextStep: number) => void;
  isInquiry?: boolean;
};

export default function CustomerServiceFormStepOne({
  shopName,
  onNext,
  isInquiry = true,
}: Props) {
  const getPageTitle = useCallback(() => {
    if (isInquiry) {
      return "is requesting a better look at your vehicle 🚗💨";
    }
    return "is preparing your vehicle for pickup. ";
  }, [isInquiry]);

  return (
    <Box my={4} py={2}>
      <Row justifyContent="center" padding="1rem">
        <Typography
          variant="h6"
          mt={3}
          sx={{ color: "#1B34CE", fontWeight: "bold" }}
        >
          {`${shopName} ${getPageTitle()}`}
        </Typography>
      </Row>
      {!isInquiry && (
        <Row justifyContent="center" padding="1rem">
          <Typography
            variant="h6"
            sx={{ color: "#1B34CE", fontWeight: "bold" }}
          >
            Preview your vehicle and confirm us at your earliest convenience
            🚗💨
          </Typography>
        </Row>
      )}
      <Box>
        <CenteredImage
          src={CarBackground}
          alt="back"
          width="100%"
          height="20rem"
        />
      </Box>
      <Button
        variant="contained"
        sx={{
          textTransform: "none",
          bgcolor: "#1B34CE",
          position: "absolute",
          right: "1rem",
          bottom: "2rem",
        }}
        onClick={() => onNext(2)}
      >
        Let's Go! 🏁
      </Button>
    </Box>
  );
}
