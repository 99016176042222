import { Label } from "../Styles/UIKit/Text";
import { NewInputText } from "../Styles/UIKit/NewInput";
import Box from "@mui/material/Box";

interface ITextControlProps {
  value: string;
  handleValueChange: (value: string) => void;
  placeholder: string;
  required?: boolean;
  label: string;
}

const TextInputControl = ({
  value,
  handleValueChange,
  label,
  placeholder,
  required,
}: ITextControlProps) => {
  return (
    <Box px={4} py={1}>
      <Label color="#1b34ce">{label}</Label>
      <NewInputText
        placeholder={placeholder}
        style={{ marginTop: "0.5rem" }}
        required={required}
        value={value}
        onChange={(e) => handleValueChange(e.target.value)}
      />
    </Box>
  );
};

export default TextInputControl;
