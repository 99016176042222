import React from 'react';
import {PaymentElement} from '@stripe/react-stripe-js';
import './Styles.css'
const CardSection = (props) =>{
  return (
    <label>
      <PaymentElement />
    </label>
  );
};
export default CardSection;