import { CenteredImage } from "../Styles/UIKit/Image";
import CarBackground from "../assets/service_scan.png";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

type Props = {
  onNext: (step: number) => void;
};

export default function CustomerServiceFormStepThree({ onNext }: Props) {
  return (
    <Box my={4} py={2}>
      <Typography
        variant="subtitle1"
        mt={3}
        px={3}
        sx={{ color: "#1B34CE", fontWeight: "bold", lineHeight: 1.5 }}
      >
        Success! We now have your contact information
      </Typography>
      <Typography
        variant="subtitle1"
        mt={3}
        px={3}
        sx={{ color: "#1B34CE", fontWeight: "bold", lineHeight: 1.5 }}
      >
        Now, let's have a quick look at your vehicle with video 🚗💨
      </Typography>
      <Box>
        <CenteredImage
          src={CarBackground}
          alt="back"
          width="100%"
          height="16rem"
        />
      </Box>
      <Button
        onClick={() => onNext(4)}
        variant="contained"
        sx={{
          textTransform: "none",
          bgcolor: "#1B34CE",
          position: "absolute",
          right: "1rem",
          bottom: "2rem",
        }}
      >
        Continue! 🏁
      </Button>
    </Box>
  );
}
