import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import CustomerInquiryPage from "./Pages/CustomerInquiryPage";
import CustomerInvoicePage from "./Pages/CustomerInvoicePage";
import CustomerEstimatePage from "./Pages/CustomerEstimatePage";
import CustomerConfirmScheduledServicePage from "./Pages/CustomerConfirmScheduledServicePage";
import AffirmPaySuccess from "./Pages/AffirmPaySuccess";
import * as serviceWorker from "./serviceWorker";
import queryString from "query-string";
import { PageType } from "./Pages/CustomerInquiryPage";

// eslint-disable-next-line no-restricted-globals
const query = queryString.parse(location.search);
if (query.key && typeof query.key === "string") {
  ReactDOM.render(
    <CustomerInquiryPage queryVal={query.key} type={PageType.Inquiry} />,
    document.getElementById("root")
  );
} else if (query.schedule_key && typeof query.schedule_key === "string") {
  ReactDOM.render(
    <CustomerInquiryPage
      queryVal={query.schedule_key}
      type={PageType.ScheduledService}
    />,
    document.getElementById("root")
  );
} else if (
  query.confirm_schedule &&
  typeof query.confirm_schedule === "string"
) {
  ReactDOM.render(
    <CustomerConfirmScheduledServicePage queryVal={query.confirm_schedule} />,
    document.getElementById("root")
  );
} else if (query.invoice_key && typeof query.invoice_key === "string") {
  ReactDOM.render(
    <CustomerInvoicePage queryVal={query.invoice_key} />,
    document.getElementById("root")
  );
} else if (query.payment_intent && typeof query.payment_intent === "string") {
  ReactDOM.render(<AffirmPaySuccess />, document.getElementById("root"));
} else if (query.inquiry_key && typeof query.inquiry_key === "string") {
  ReactDOM.render(
    <CustomerEstimatePage queryVal={query.inquiry_key} />,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(<App />, document.getElementById("root"));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
