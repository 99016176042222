import React, { useEffect, useState } from "react";
import jobsAPI from "../ClientAPI/jobsAPI";
import { Container } from "../Styles/Layout/Container";
import { formatPhoneNumber } from "../service/helper";
import CustomerScheduledServiceConfirm from "../Components/CustomerScheduledServiceConfirm";
import "./CustomerInquiryPage.css";

interface IConfirmPageToken {
  queryVal: string;
}

export interface IShopInfo {
  businessName: string;
  businessAddress: string;
  shopTelephone: string;
}

export interface IScheduledServiceInfo {
  customerPhone: string;
  customerFirstName: string;
  customerLastName: string;
  customerEmail: string;
  carModel: string;
  service: string;
  note?: string;
  scheduledAt: string;
  isConfirmed: boolean;
}

const CustomerConfirmScheduledServicePage = ({
  queryVal,
}: IConfirmPageToken) => {
  const [isLoading, setIsLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [service, setService] = useState<IScheduledServiceInfo>();
  const [shop, setShop] = useState<IShopInfo>();

  useEffect(() => {
    jobsAPI
      .getScheduledInfo(queryVal)
      .then((data) => {
        setService(data.serviceInfo);
        setShop(data.shopInfo);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  const confirmScheduledService = async () => {
    try {
      const res = await jobsAPI.confirmScheduledService(queryVal);
      if (res.status === 200) {
        setSubmitted(true);
      }
    } catch (err: any) {
      window.alert(err.message);
    }
  };

  return (
    <Container>
      {submitted ? (
        <p className="font-big-center">
          <b>Successfully Submitted</b> <br />
          <br />
          We'll reply to further assist with your vehicle service shortly 🚗💨
        </p>
      ) : !isLoading ? (
        service && shop && !service.isConfirmed ? (
          <CustomerScheduledServiceConfirm
            shopInfo={shop}
            serviceInfo={service}
            onSubmit={confirmScheduledService}
          />
        ) : (
          <h2 className="font-big-center">
            Oops…page expired, text shop again to receive new form <br />
            <br />
            {shop && shop.shopTelephone ? (
              <p>Text : {formatPhoneNumber(shop.shopTelephone)} </p>
            ) : (
              ""
            )}
          </h2>
        )
      ) : (
        <h1 className="main-header">Loading...</h1>
      )}
    </Container>
  );
};

export default CustomerConfirmScheduledServicePage;
