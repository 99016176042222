import styled from "styled-components";

interface IButtonProps {
    display?: string;
}

export const PrimaryButton = styled.button`
    background-color: rgb(27, 52, 206);
    border-radius: .4rem;
    color: white;
    display: ${(props: IButtonProps) => props.display ? props.display : "inline-block"};
    font-size: 0.93rem;
    font-weight: bold;
    padding: 0.9rem 2.4rem;
`;

export const PayButton = styled.button`
    background-color: rgb(27, 52, 206);
    border-radius: .4rem;
    color: white;
    display: ${(props: IButtonProps) => props.display ? props.display : "inline-block"};
    font-size: 0.93rem;
    font-weight: bold;
    padding: 0.9rem 2.4rem;
    width: 100%;
`;