import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import SuccessIcon from "../assets/icon_success.png";
import { Image } from "../Styles/UIKit/Image";
import VideoAPIPreview from "./VideoAPIPreview";
import { useCallback, useState } from "react";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

type Props = {
  onNext: (step: number, videoUrl?: string) => void;
  userName: string;
  isApiLoading: boolean;
};

export enum VideoUploadingStatus {
  waiting = "Tap to begin recording",
  recording = "Recording video now",
  encoding = "Video Is Uploading...",
  playable = "Recorded video uploaded successfully",
}

export default function CustomerServiceVideoForm({
  onNext,
  userName,
  isApiLoading,
}: Props) {
  const [statusString, setStatusString] = useState<VideoUploadingStatus>(
    VideoUploadingStatus.waiting
  );
  const [isRetake, setIsRetake] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | undefined>(undefined);

  const handleStatusChange = useCallback(
    (status: VideoUploadingStatus, videoUrl?: string) => {
      setStatusString(status);
      if (videoUrl) {
        setVideoUrl(videoUrl);
      }
    },
    []
  );

  const handleRetake = () => {
    setVideoUrl(undefined);
    setIsRetake(true);
  };

  return (
    <Box my={4} py={2}>
      <Typography
        variant="subtitle1"
        mt={3}
        px={3}
        sx={{ color: "#1B34CE", fontWeight: "bold", lineHeight: 1.5 }}
      >
        Walk around your vehicle while recording and share as much as you can 🚘
      </Typography>
      <VideoAPIPreview
        onVideoStatusChange={handleStatusChange}
        userName={userName}
        isRetake={isRetake}
      />
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          boxShadow: "0px -5.387px 26.934px 2.693px rgba(0, 0, 0, 0.35)",
          borderRadius: "8px",
        }}
        py={2}
      >
        {statusString && (
          <Stack direction="row" spacing={2} justifyContent="center">
            {statusString !== VideoUploadingStatus.waiting && (
              <CheckCircleIcon
                sx={{
                  color:
                    statusString === VideoUploadingStatus.encoding
                      ? "#FFAE13"
                      : "#4CAF50",
                }}
              />
            )}
            {statusString === VideoUploadingStatus.waiting ? (
              <>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  Tap{" "}
                  <RadioButtonCheckedIcon
                    fontSize="small"
                    sx={{ verticalAlign: "middle", color: "red" }}
                  />{" "}
                  to begin recording
                </Typography>
              </>
            ) : (
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", ml: "0.5rem" }}
              >
                {statusString}
              </Typography>
            )}
          </Stack>
        )}
        <Stack
          direction="row"
          mt={3}
          spacing={2}
          justifyContent="center"
          sx={{ justifyContent: "space-around" }}
        >
          <Button
            variant="contained"
            sx={{
              width: "6rem",
              bgcolor: "#FFAE13",
              textTransform: "none",
              color: "#000000",
            }}
            onClick={handleRetake}
            disabled={statusString !== VideoUploadingStatus.playable}
          >
            Retake
          </Button>
          <LoadingButton
            variant="contained"
            disabled={!videoUrl}
            loading={isApiLoading}
            onClick={() => onNext(5, videoUrl)}
            sx={{ width: "6rem", bgcolor: "#1B34CE", textTransform: "none" }}
          >
            Done!
          </LoadingButton>
        </Stack>
      </Box>
    </Box>
  );
}
