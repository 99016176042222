import React, {useState} from "react";
import Form from "../PureComponents/Form";
import { Row } from "../Styles/Layout/Row";
import { Header1, Header3, Text, Label} from "../Styles/UIKit/Text";
import { Card } from "../Styles/UIKit/Card";
import {PrimaryButton} from "../Styles/UIKit/Button";
import {Devider} from "../Styles/UIKit/Devider";
import { Image } from "../Styles/UIKit/Image";
import Group3 from "../assets/motorvate.png";
import { Col } from "../Styles/Layout/Col";
import { formatPrice, calculateFeeAndTotal, formatPhoneNumber } from "../service/helper";

interface ICustomerInquiryFormProps {
    shopName: string;
    shopAddress: string;
    shopPhone: string;
    invoiceData: any;
    onSubmit: () => Promise<void>;
    isPreview: boolean;
}

const CustomerInquiryForm = ({shopName, shopAddress, shopPhone, invoiceData, onSubmit, isPreview}: ICustomerInquiryFormProps) => {
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {

        setLoading(true);
        try {
           await onSubmit(); 
        } catch {
            setLoading(false);
        }
    }

    return (
        <Form handleSubmit={handleSubmit}>
            <Row justifyContent="center">
                <Header1 size="1.56rem" weight="700">{shopName}</Header1>
            </Row>
            <Row margin="2rem auto" padding=".2rem 1.6rem" maxWidth="50rem">
                <Col grow={12}>
                    <Text size="1rem" weight="500" textAlign="center">
                        🚗💨 {shopAddress || ""}
                        <br /><br />
                        Text Anytime: {formatPhoneNumber(shopPhone)}
                    </Text>
                </Col>
            </Row>
            <Card>
                <Header3 margin="1rem" size="1.125rem" weight="700">Contact Information</Header3>
                <Row>
                    <Col grow={1} >
                        <Label weight="500">{invoiceData.contactInfo.name}</Label>
                    </Col>
                </Row>
                <Row>
                    <Col grow={1} >
                        <Label weight="500">{invoiceData.contactInfo.phone}</Label>
                    </Col>
                </Row>
                <Row>
                    <Col grow={1} >
                        <Label weight="500">{invoiceData.contactInfo.email}</Label>
                    </Col>
                </Row>
            </Card>
            <Card>
                <Header3 margin="1rem" size="1.125rem" weight="700">Job Information</Header3>
                <Row>
                    <Col grow={1} >
                        <Label weight="500">{invoiceData.jobInfo.vehicleInfo}</Label>
                    </Col>
                </Row>
                <Row>
                    <Col grow={1} >
                        <Label weight="500">{invoiceData.jobInfo.vin}</Label>
                    </Col>
                </Row>
            </Card>
            <Card>
                <Header3 margin="1rem" size="1.125rem" weight="700">Service Information</Header3>
                {invoiceData.serviceInfo.map((item: any, index: number) => (
                    <React.Fragment key={index}>
                        <Row>
                            <Col grow={6} >
                                <Label weight="500">{item.service}</Label>
                            </Col>
                            <Col grow={6} justifyContent="flex-end">
                                <Label weight="500" color="rgb(27 52 206)">{formatPrice(item.price)}</Label>
                            </Col>
                        </Row>
                        {(index + 1 === invoiceData.serviceInfo.length) && <Devider />}
                    </React.Fragment>
                ))}
                {invoiceData.inquiryPaidDetail && (<>
                <Row margin="24px 10px 10px">
                    <Col grow={6} >
                        <Label weight="500">{`Paid (${invoiceData.inquiryPaidDetail.percent}%)`}</Label>
                    </Col>
                    <Col grow={6} justifyContent="flex-end">
                        <Label weight="500" color="rgb(27 52 206)">{calculateFeeAndTotal(invoiceData.inquiryPaidDetail.price, true).total}</Label>
                    </Col>
                </Row>
                <Devider />
                </>)}
                <Row margin="0 10px 10px">
                    <Col grow={6} >
                        <Label weight="600, Semi Bold">Convenience  Fee</Label>
                    </Col>
                    <Col grow={6} justifyContent="flex-end">
                        <Label weight="600, Semi Bold" color="rgb(27 52 206)">{calculateFeeAndTotal(invoiceData.total, invoiceData.feeFromShop).fee}</Label>
                    </Col>
                </Row>
                <Row margin="0 10px 10px">
                    <Col grow={6} >
                        <Label weight="700" size="1rem">Amount Due</Label>
                    </Col>
                    <Col grow={6} justifyContent="flex-end">
                        <Label weight="700" size="1rem" color="rgb(27 52 206)">{calculateFeeAndTotal(invoiceData.total, invoiceData.feeFromShop).total}</Label>
                    </Col>
                </Row>
            </Card>
            <Row>
                <Text size="0.937rem" weight="500" textAlign="start">
                    {bottomText1}
                    <br /><br />
                    {bottomText2}
                </Text>
            </Row>
            <Row margin="1rem auto" padding=".2rem 1.6rem" maxWidth="50rem" justifyContent="space-between">
                <Image src={Group3} width="9rem" />
                {!isPreview && (
                    <PrimaryButton disabled={loading}>{!loading ? "Confirm" : "Sending..."}</PrimaryButton>
                )}
            </Row>
        </Form>
    )
}

const bottomText1 = `We attached your estimate and deposit above. Once you confirm your estimate you will be redirected to checkout. 😊`;
const bottomText2 = `Payment  installments with Affirm available at checkout`
export default CustomerInquiryForm;