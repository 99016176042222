import styled from "styled-components";

export const NewInputText = styled.input`
  border: 1px solid #eef4ff;
  border-radius: 4px;
  box-shadow: 0 1px 5px -2px rgba(0, 0, 0, 0.09);
  box-sizing: border-box;
  font-size: 0.93rem;
  font-weight: bold;
  color: rgb(20, 20, 20);
  padding: 16.5px 14px;
  width: 100%;
  background: #f6f9ff;
`;
