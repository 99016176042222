import React, { useState } from "react";
import Form from "../PureComponents/Form";
import { Row } from "../Styles/Layout/Row";
import { Header1, Header2, Header3, Text, Label } from "../Styles/UIKit/Text";
import { Card } from "../Styles/UIKit/Card";
import { PrimaryButton } from "../Styles/UIKit/Button";
import { Image } from "../Styles/UIKit/Image";
import Group3 from "../assets/motorvate.png";
import { Col } from "../Styles/Layout/Col";
import {
  IShopInfo,
  IScheduledServiceInfo,
} from "../Pages/CustomerConfirmScheduledServicePage";
import { formatPhoneNumber } from "../service/helper";
import dayjs from "dayjs";

interface ICustomerInquiryFormProps {
  shopInfo: IShopInfo;
  serviceInfo: IScheduledServiceInfo;
  onSubmit: () => Promise<void>;
}

const CustomerScheduledServiceConfirm = ({
  shopInfo,
  serviceInfo,
  onSubmit,
}: ICustomerInquiryFormProps) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await onSubmit();
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  return (
    <Form handleSubmit={handleSubmit}>
      <Row justifyContent="center">
        <Header1 size="1.56rem" weight="700">
          {shopInfo.businessName}
        </Header1>
      </Row>
      <Row margin="2rem auto" padding=".2rem 1.6rem" maxWidth="50rem">
        <Col grow={12} justifyContent="center">
          <Text size="0.875rem" weight="500" textAlign="center">
            🚗💨 {shopInfo.businessAddress || ""}
            <br />
            <br />
            Text Anytime: {formatPhoneNumber(shopInfo.shopTelephone)}
          </Text>
        </Col>
      </Row>
      <Card>
        <Header3 margin="1rem" size="1.125rem" weight="700">
          Contact Information
        </Header3>
        <Row>
          <Col grow={1}>
            <Label weight="500">{`${serviceInfo.customerFirstName} ${serviceInfo.customerLastName}`}</Label>
          </Col>
        </Row>
        <Row>
          <Col grow={1}>
            <Label weight="500">{formatPhoneNumber(serviceInfo.customerPhone)}</Label>
          </Col>
        </Row>
        <Row>
          <Col grow={1}>
            <Label weight="500">{serviceInfo.customerEmail}</Label>
          </Col>
        </Row>
      </Card>
      <Card>
        <Header3 margin="1rem" size="1.125rem" weight="700">
          Job Information
        </Header3>
        <Row>
          <Col grow={1}>
            <Label weight="600">{serviceInfo.carModel}</Label>
          </Col>
        </Row>
        <Row>
          <Col grow={1}>
            <Label weight="400">{serviceInfo.note}</Label>
          </Col>
        </Row>
      </Card>
      <Card>
        <Header3 margin="1rem" size="1.125rem" weight="700">
          Scheduled Drop-Off
        </Header3>
        <Row>
          <Col grow={1}>
            <Label weight="500">{dayjs(serviceInfo.scheduledAt).format('dddd h:mm A')}</Label>
          </Col>
        </Row>
        <Row>
          <Col grow={1}>
            <Label weight="500">{dayjs(serviceInfo.scheduledAt).format('MMMM D, YYYY')}</Label>
          </Col>
        </Row>
      </Card>
      <Row>
        <Text size="0.937rem" weight="500" textAlign="start">
          {bottomText1}
        </Text>
      </Row>
      <Row
        margin="1rem auto"
        padding=".2rem 1.6rem"
        maxWidth="50rem"
        justifyContent="space-between"
      >
        <Image src={Group3} width="9rem" />
        <PrimaryButton disabled={loading}>
          {!loading ? "Confirm" : "Sending..."}
        </PrimaryButton>
      </Row>
    </Form>
  );
};

const bottomText1 = `Details of your upcoming service. Once you confirm we'll send a reminder of your drop-off for scheduled service. 😊 `;

export default CustomerScheduledServiceConfirm;
