import React from "react";
import ReactDOM from "react-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Card } from "../../Styles/UIKit/Card";

import CheckoutForm from "./CheckoutForm";

const StripeCheckout = (props) => { 
  const stripePromise = loadStripe(props.intent.stripeKey);
  const appearance = {
    theme:"night",
    variables:{
       fontFamily:"Sohne, system-ui, sans-serif",
       fontWeightNormal:"500",
       borderRadius:"9px",
       colorBackground:"#1B34CE",
       colorPrimary:"#FFAE13",
       colorPrimaryText:"#1A1B25",
       colorText:"white",
       colorTextSecondary:"white",
       colorTextPlaceholder:"#727F96",
       colorIconTab:"white",
       colorLogo:"dark"
    },
    rules:{
       ".Input, .Block":{
          backgroundColor:"transparent",
          border:"1.5px solid var(--colorPrimary)"
       }
    }
  }

  const options = {
    clientSecret: props.intent.clientSecret,
    // appearance,
  };

  return (
    <>
      <Card>
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm intent={props.intent} />
        </Elements>
      </Card>
    </>
  );
};

export default StripeCheckout;
