import React from "react";

interface IFormProps {
    children: any;
    handleSubmit: any;
    style?: any;
}

const Form = ({children, handleSubmit, style}: IFormProps) => {

    const onSubmit = (e: any) => {
        e.preventDefault();
        handleSubmit();
    }

    return (
        <form onSubmit={onSubmit} style={{...style}}>
            {children}
        </form>
    )
}

export default Form;