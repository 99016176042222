import styled from "styled-components";

export interface IStyledRowProps {
  alignItems?: "center" | "flex-end" | "flex-start" | "stretch" | "baseline";
  justifyContent?:
    | "center"
    | "flex-end"
    | "flex-start"
    | "space-between"
    | "space-around";
  margin?: string;
  padding?: string;
  maxWidth?: string;
}

export const Row = styled.div`
  display: flex;
  align-items: ${(props: IStyledRowProps) =>
    props.alignItems ? props.alignItems : "center"};
  justify-content: ${(props: IStyledRowProps) =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  margin: ${(props) => (props.margin ? props.margin : "0.2rem 0")};
  padding: ${(props) => (props.padding ? props.padding : ".4rem .2rem")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
`;
