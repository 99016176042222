import styled from "styled-components";

export const Container = styled.div`
  background-color: ${(props) =>
    props.color ? props.color : "rgb(246, 246, 246)"};
  margin 0 auto;
  padding: 1.8rem 1.7rem;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
