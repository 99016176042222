import { useState } from "react";
import Form from "../PureComponents/Form";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import TextInputControl from "./TextInputControl";
import MaskInputControl from "./MaskInputContorl";
import TextAreaControl from "./TextAreaControl";
import { formatPhoneNumber } from "../service/helper";
import { PageType, UserInfo } from "../Pages/CustomerInquiryPage";
import { Alert } from "./VideoAPIPreview";

type Props = {
  shopAddress: string;
  shopName: string;
  shopPhone: string;
  pageType: PageType;
  onSubmit: (userInfo: UserInfo) => void;
  onNext: (nextStep: number) => void;
};

export default function CustomerServiceFormStepTwo({
  shopAddress,
  shopName,
  shopPhone,
  pageType,
  onSubmit,
  onNext,
}: Props) {
  const [phone, setPhone] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [carModel, setCarModel] = useState("");
  const [service, setService] = useState("");

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorMessage(undefined);
  };

  const handleContinue = () => {
    console.log("Clicking submit...");
    setErrorMessage("New Error message");
    const phoneNumber = `1${phone.replace(/[^0-9]/g, "")}`;
    const splitName = fullName.split(" ");
    if (splitName.length < 2) {
      setErrorMessage(
        "Please input correct full name. Full name has to be separated into space"
      );
      return;
    }
    const firstName = splitName[0];
    const lastName = splitName[1];
    const userInfo: UserInfo = {
      customerFirstName: firstName,
      customerLastName: lastName,
      customerEmail: email,
      customerPhone: phoneNumber,
      carModel,
      service,
    };
    onSubmit(userInfo);
    if (pageType === PageType.ScheduledService) {
      onNext(6);
      return;
    }
    onNext(3);
  };

  return (
    <Box my={4} py={2}>
      <Typography
        variant="h6"
        mt={3}
        sx={{
          color: "#1B34CE",
          fontWeight: "bold",
          textAlign: "center",
          fontSize: "1.3rem",
        }}
      >
        {shopName}
      </Typography>
      <Box mt={3} sx={{ px: "4rem" }} textAlign="center">
        <Typography variant="subtitle2" sx={{ lineHeight: 1.5 }}>
          {shopAddress} Text anytime:&nbsp;
          <Typography
            variant="subtitle2"
            component="span"
            sx={{ color: "#1B34CE" }}
          >
            {formatPhoneNumber(shopPhone)}
          </Typography>
        </Typography>
      </Box>
      <Box sx={{ px: "2rem", color: "#1B34CE", mt: "3rem" }} textAlign="left">
        <Typography
          variant="body1"
          sx={{
            fontSize: "1.1rem",
            color: "#1B34CE",
            fontWeight: "bold",
            lineHeight: 1.5,
          }}
        >
          First, let's get your name and contact information:
        </Typography>
      </Box>
      <Form handleSubmit={handleContinue}>
        <Box mt={3}>
          <TextInputControl
            required
            label="Full Name"
            value={fullName}
            placeholder="eg: John Doe"
            handleValueChange={setFullName}
          />
          <TextInputControl
            required
            label="Email"
            value={email}
            placeholder="eg: john@email.com"
            handleValueChange={setEmail}
          />
          <MaskInputControl
            required
            label="Phone Number"
            value={phone}
            handleValueChange={setPhone}
          />
          <TextInputControl
            required
            label="Vehicle"
            value={carModel}
            placeholder="eg: Ford Fusion"
            handleValueChange={setCarModel}
          />
          <TextAreaControl
            required
            label="Service Description"
            value={service}
            placeholder="eg: Description of Service"
            handleValueChange={setService}
          />
        </Box>
        <Button
          variant="contained"
          type="submit"
          sx={{
            textTransform: "none",
            bgcolor: "#1B34CE",
            my: 3,
            mr: "2rem",
            float: "right",
          }}
        >
          Continue! 🏁
        </Button>
      </Form>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
