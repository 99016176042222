import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SuccessImage from "../assets/service_success.png";
import { Image } from "../Styles/UIKit/Image";

type Props = {
  shopName: string;
  shopAddress: string;
  customerName: string;
  title1: string;
  title2: string;
};

export default function CustomerServiceFormStepTwo({
  shopName,
  shopAddress,
  customerName,
  title1,
  title2,
}: Props) {
  return (
    <>
      <Box py={3} sx={{ bgcolor: "#1B34CE" }}>
        <Typography
          variant="h6"
          mt={5}
          sx={{
            color: "#FFFFFF",
            fontWeight: "bold",
            textAlign: "center",
            fontSize: "1.3rem",
          }}
        >
          {shopName}
        </Typography>
        <Box mt={1} sx={{ px: "2rem" }} textAlign="center">
          <Typography
            variant="subtitle2"
            sx={{ lineHeight: 1.5, color: "white" }}
          >
            {shopAddress}
          </Typography>
        </Box>
        <Box sx={{ px: "2rem", mt: "2.5rem", mb: "1rem" }} textAlign="center">
          <Typography
            variant="body1"
            sx={{ fontSize: "1rem", color: "#FFFFFF", fontWeight: "bold" }}
          >
            Thank You, {customerName}!
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              color: "#FFFFFF",
              fontWeight: "bold",
              mt: 3,
            }}
          >
            {`${title1 || ""} ${shopName}`}
          </Typography>
        </Box>
      </Box>
      <Image src={SuccessImage} alt="background" width="100%" />
      <Box px={2} py={3}>
        <Typography variant="subtitle1" sx={{ lineHeight: 1.5 }}>
          {title2}
        </Typography>
        <Typography mt={4} variant="subtitle1" textAlign="center">
          We'll follow up shortly 🚗💨
        </Typography>
      </Box>
    </>
  );
}
