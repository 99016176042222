/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Container } from "../Styles/Layout/Container";

const AffirmPaySuccess = () => {

  return (
    <Container>
        <h1 className="result-msg">
        Payment could be completed.
        <br />
        Please contact your shop or service provider
        </h1>
    </Container>
  );
};

export default AffirmPaySuccess;
