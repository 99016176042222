import React, { useState, useEffect, useCallback } from "react";

import styles from "../Styles/video.module.css";
import {
  MediaStreamComposer,
  StreamDetails,
} from "@api.video/media-stream-composer";
import VideoOff from "../assets/video-off.svg";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import StopIcon from "@mui/icons-material/Stop";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { VideoUploadingStatus } from "./CustomerServiceVideoForm";
import Snackbar from "@mui/material/Snackbar";
import { StreamFormValues } from "../Components/StreamDialog";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import moment from "moment";

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  }
);

const WIDTH = 1600;
const HEIGHT = 1000;
const DEFAULT_UPLOAD_TOKEN = "to6Z7QZuqTKIk4wwQ2tql0uw";

const composer = (() => {
  const mediaStreamComposer = new MediaStreamComposer({
    resolution: {
      width: WIDTH,
      height: HEIGHT,
    },
  });
  mediaStreamComposer.addEventListener("recordingStopped", (e: any) => {
    if (e.data.file) {
      const a = document.createElement("a");
      console.log(e.data.file);
      let extension = "mp4";
      try {
        extension = e.data.file.type.split("/")[1].split(";")[0];
      } catch (e) {
        console.error(e);
      }
      a.href = URL.createObjectURL(e.data.file);
      a.download = `video.${extension}`;
      a.click();
    }
  });
  return mediaStreamComposer;
})();

type Props = {
  onVideoStatusChange: (
    status: VideoUploadingStatus,
    videoUrl?: string
  ) => void;
  userName: string;
  isRetake: boolean;
};

export default function VideoAPIPreview({
  onVideoStatusChange,
  userName,
  isRetake,
}: Props) {
  const [streams, setStreams] = useState<StreamDetails[]>([]);
  const [videoDevices, setVideoDevices] = useState<InputDeviceInfo[]>([]);
  const [audioDevices, setAudioDevices] = useState<InputDeviceInfo[]>([]);
  const [audioSource, setAudioSource] = useState<string>("none");
  const [audioStreamId, setAudioStreamId] = useState<string | undefined>();
  const [isRecording, setIsRecording] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorMessage(undefined);
  };

  // Video menu item
  const [cameraAnchor, setCameraAnchor] = React.useState<null | HTMLElement>(
    null
  );
  const openCameraMenu = Boolean(cameraAnchor);
  const handleCameraMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setCameraAnchor(event.currentTarget);
  };
  const handleCameraMenuClose = () => {
    setCameraAnchor(null);
  };
  // Audio menu item
  const [audioAnchor, setAudioAnchor] = React.useState<null | HTMLElement>(
    null
  );
  const openAudioMenu = Boolean(audioAnchor);
  const handleAudioMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAudioAnchor(event.currentTarget);
  };

  const handleAudioMenuClose = () => {
    setAudioAnchor(null);
  };

  const handleAudioSource = useCallback(async (deviceId: string) => {
    if (audioStreamId) {
      composer.removeAudioSource(audioStreamId);
    }
    const selectedAudioSource = deviceId;
    let newAudioStreamId;
    if (selectedAudioSource !== "none") {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: selectedAudioSource },
      });
      newAudioStreamId = await composer.addAudioSource(stream);
    }
    setAudioStreamId(newAudioStreamId);
    setAudioSource(selectedAudioSource);
    setAudioAnchor(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addStream = useCallback(async (opts: StreamFormValues) => {
    let stream: MediaStream | HTMLImageElement;
    switch (opts.type) {
      case "screen":
        stream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
          audio: false,
        });
        break;
      case "webcam":
        stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: { deviceId: opts.deviceId },
        });
        break;
      case "image":
        const image = new Image();
        image.crossOrigin = "anonymous";
        image.src = opts.imageUrl!;
        stream = image;
    }

    setTimeout(async () => {
      await composer.addStream(stream, {
        position: opts.position,
        width: opts.width
          ? (parseInt(opts.width, 10) * WIDTH) / 100
          : undefined,
        height: opts.height
          ? (parseInt(opts.height, 10) * HEIGHT) / 100
          : undefined,
        x: opts.left ? (parseInt(opts.left, 10) * WIDTH) / 100 : undefined,
        y: opts.top ? (parseInt(opts.top, 10) * HEIGHT) / 100 : undefined,
        resizable: opts.resizable,
        draggable: opts.draggable,
        opacity: opts.opacity,
        mask: opts.mask,
        mute: true,
        name: `${opts.type}`,
      });
      composer.appendCanvasTo("#canvas-container");
      const canvas = composer.getCanvas();
      canvas!.style.width = "100%";
      canvas!.style.height = "100%";
      canvas!.style.boxSizing = "unset";
      console.log(
        "=====Stream Info===",
        composer.getStreams()[composer.getStreams().length - 1]
      );
      const streamInfo =
        composer.getStreams()[composer.getStreams().length - 1];
      if (streamInfo) {
        setStreams([...streams, streamInfo]);
      }
    }, 100);
  }, []);

  const handleChangeVideoDevice = useCallback(
    async (deviceId: string) => {
      console.log("===ID===", deviceId);
      await addStream({
        type: "webcam",
        deviceId: deviceId,
        position: "cover",
        mask: "none",
        draggable: true,
        resizable: true,
      });
      setCameraAnchor(null);
    },
    [addStream]
  );

  const onRecordButtonClicked = useCallback(() => {
    if (streams.length) {
      console.log("=====Clicked Recording=====");
      if (!isRecording) {
        composer.startRecording({
          uploadToken: DEFAULT_UPLOAD_TOKEN,
          videoName: `${userName}_${moment().format("YYYY_MM_DD_HH_mm_ss")}`,
          generateFileOnStop: false,
        });
        console.log("===== Recording now =====");
        onVideoStatusChange(VideoUploadingStatus.recording);
        composer.addEventListener("error", (e) => {
          console.log("=====Error while recording====");
          setErrorMessage((e as any).data.title || "An unknown error occurred");
          setIsRecording(false);
        });
        composer.addEventListener("videoPlayable", (e: any) => {
          onVideoStatusChange(
            VideoUploadingStatus.playable,
            e.data.assets.player
          );
          console.log("===== Video is Playable =====", e);
        });

        setIsRecording(true);
      } else {
        composer.stopRecording().then((e) => {
          console.log("=====Recording Stopped=====", e);
          onVideoStatusChange(VideoUploadingStatus.encoding);
        });
        setIsRecording(false);
      }
    } else {
      setErrorMessage("Please select the camera first");
    }
  }, [isRecording, onVideoStatusChange, streams.length, userName]);

  useEffect(() => {
    if (streams.length === 0 && document.querySelector("canvas")) {
      document
        .getElementById("canvas-container")!
        .removeChild(document.querySelector("canvas")!);
    }
  }, [streams]);

  useEffect(() => {
    if (videoDevices.length > 0) {
      console.log("========Called Add stream function======");
      // addStream({
      //   type: "webcam",
      //   deviceId: videoDevices[0].deviceId,
      //   position: "cover",
      //   mask: "none",
      //   draggable: true,
      //   resizable: true,
      // });
    }
  }, [addStream, videoDevices]);

  useEffect(() => {
    if (isRetake) {
      onRecordButtonClicked();
    }
  }, [isRetake]);

  // retrieve the list of webcam on init
  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then((stream) => {
        navigator.mediaDevices.enumerateDevices().then((devices) => {
          setVideoDevices(devices.filter((d) => d.kind === "videoinput"));
          setAudioDevices(devices.filter((d) => d.kind === "audioinput"));
          stream.getTracks().forEach((x) => x.stop());
        });
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    if (audioDevices.length > 0) {
      handleAudioSource(audioDevices[0].deviceId);
      console.log("====Audio Devices====", audioDevices[0].deviceId);
    }
    if (videoDevices.length > 0) {
      handleChangeVideoDevice(videoDevices[0].deviceId);
      console.log("====Video Devices====", videoDevices[0].deviceId);
    }
  }, [audioDevices, handleAudioSource, handleChangeVideoDevice, videoDevices]);

  return (
    <Box className={styles.previewPaper} py={4} px={3}>
      <div
        id="canvas-container"
        className={styles.canvasContainer}
        style={{ width: "100%", aspectRatio: `${WIDTH}/${HEIGHT}` }}
      >
        {streams.length === 0 && (
          <>
            <img src={VideoOff} alt="No stream" width={48} height={48} />
            <p>No video stream yet</p>
          </>
        )}
      </div>
      <Card
        sx={{
          borderRadius: "2rem",
          mt: "3rem",
          mx: "2.5rem",
          p: 1,
          bgcolor: "#1B34CE",
          height: "3.2rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack direction="row" spacing={4}>
          <Button
            variant="contained"
            sx={{
              minWidth: 0,
              p: "5px",
              width: "2.5rem",
              height: "2.5rem",
              borderRadius: "50%",
              background: "rgba(0, 0, 0, 0.52)",
            }}
            onClick={handleCameraMenuClick}
          >
            {streams.length ? <VideocamIcon /> : <VideocamOffIcon />}
          </Button>
          <Menu
            id="camera-menu"
            anchorEl={cameraAnchor}
            open={openCameraMenu}
            onClose={handleCameraMenuClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {/* <MenuItem
              key={d.deviceId}
              onClick={async () => {
                popupState.close();
                addStream({
                  type: "webcam",
                  deviceId: d.deviceId,
                  position: "fixed",
                  height: "30%",
                  top: "68%",
                  left: "2%",
                  mask: "circle",
                  draggable: true,
                  resizable: true,
                });
              }}
            >
              Add rounded webcam only ({d.label})
            </MenuItem> */}
            {videoDevices.map((device) => (
              <MenuItem
                key={device.deviceId}
                onClick={() => handleChangeVideoDevice(device.deviceId)}
              >
                {device.label}
              </MenuItem>
            ))}
          </Menu>
          <Button
            variant="contained"
            sx={{
              minWidth: 0,
              p: "5px",
              width: "2.5rem",
              height: "2.5rem",
              borderRadius: "50%",
              background: "#FE2724",
              "&:hover": {
                color: "white",
                backgroundColor: "#FE2724",
              },
            }}
            onClick={onRecordButtonClicked}
          >
            {isRecording ? <StopIcon /> : <RadioButtonCheckedIcon />}
          </Button>
          <Button
            variant="contained"
            sx={{
              minWidth: 0,
              p: "5px",
              width: "2.5rem",
              height: "2.5rem",
              borderRadius: "50%",
              background: "#5B9104",
            }}
            onClick={handleAudioMenuClick}
          >
            {audioSource === "none" ? <MicOffIcon /> : <MicIcon />}
          </Button>
          <Menu
            id="audio-menu"
            anchorEl={audioAnchor}
            open={openAudioMenu}
            onClose={handleAudioMenuClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              key={"undefined"}
              value={"none"}
              onClick={() => handleAudioSource("none")}
            >
              None
            </MenuItem>
            {audioDevices.map((d) => (
              <MenuItem
                key={d.deviceId}
                value={d.deviceId}
                onClick={() => handleAudioSource(d.deviceId)}
              >
                {d.label}
              </MenuItem>
            ))}
          </Menu>
        </Stack>
      </Card>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
