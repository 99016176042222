const capitalizeFirstLetter = (aname) => {
  return aname.charAt(0).toUpperCase() + aname.slice(1);
};

const calculatePriceFromInquiryDetails = (
  data,
  feeFromShop,
  isServiceQuote
) => {
  let total = 0;
  data.forEach((item) => {
    total += item.price;
  });
  if (isServiceQuote) {
    return { total: `$${total}`, fee: `$0` };
  }
  const price = (total * data[0].percent) / 100;
  let expectedTotal = !feeFromShop
    ? ((price + 0.3) / (1 - 0.035)).toFixed(2)
    : price.toFixed(2);
  let fee = !feeFromShop
    ? (Math.ceil(expectedTotal * 3.5 + 30) / 100).toFixed(2)
    : 0;
  return { total: `$${expectedTotal}`, fee: `$${fee}` };
};

const formatPrice = (price) => {
  const formattedPrice = price.toFixed(2);
  return `$${formattedPrice}`;
};

const calculateFeeAndTotal = (price, feeFromShop) => {
  const feeAddedValue = !feeFromShop
    ? ((price + 0.3) / (1 - 0.035)).toFixed(2)
    : price;
  const fee = !feeFromShop
    ? (Math.ceil(feeAddedValue * 3.5 + 30) / 100).toFixed(2)
    : 0;
  return { total: `$${feeAddedValue}`, fee: `$${fee}` };
};

const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

export {
  capitalizeFirstLetter,
  calculatePriceFromInquiryDetails,
  formatPrice,
  calculateFeeAndTotal,
  formatPhoneNumber,
};
